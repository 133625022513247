@import '../addscriptpanel.scss';

.add-script-button-container {
  width: 150px;
  min-width: 150px; 
  height: 95px;
  background-color: $editor-popout-primary;
  color: $editor-popout-primary-text;
  border-radius: 4px; 
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 6px;  
}

.add-script-button-container.already-used-script {
  background-color: $editor-popout-tertiary;
  color: $editor-popout-tertiary-text;
}

.add-script-button-container:hover {
  background-color: $editor-popout-secondary;
  color: $editor-popout-secondary-text;
}


.add-script-button-container.already-used-script:hover {
  background-color: $editor-popout-tertiary;
  color: $editor-popout-tertiary-text;
  cursor: default; 
}

.add-script-icon {
  width: 15px;
  height: 15px; 
  margin-right: 4px;
}

.add-script-button-title {
  display: flex;
  height: 20px; 
  width: 100%; 
  align-items: center;
  padding-left: 4px; 
  font-size: 16px; 
  margin-top: 2px;
}

.add-script-description {
  padding: 10px; 
  padding-left: 4px; 
  font-size: 12px; 
}