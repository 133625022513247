/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

 @import 'Editor/_wickbrand.scss';


.project-settings-modal-body {
   width: 240px;
   min-width: 240px;  
   height: 370px;
   padding: 20px; 
 }

#project-settings-modal-title {
  font-size: 18px;
  color: $editor-modal-text-gray;
  font-weight: bold; 
  text-align: center;
}

.project-settings-property-label {
  color: $editor-modal-text-gray; 
}

.project-settings-size-input-container {
  display: flex;
  flex-direction: row; 
}

.project-settings-split {
  padding-left: 5px; 
  padding-right: 5px;
  vertical-align: center;
  text-align: center;
}

.project-setting-container {
  margin-top: 8px; 
}

.project-settings-size-input {
  float: right;
}
 #project-settings-modal-interior-content {
   display: flex;
   flex-direction: column;
   width: 100%; 
   height: 100%;
   justify-content: center;
   align-items: center;
 }
 
 #project-settings-modal-footer {
   width: 100%; 
   height: 28px;
   margin-top: 25px;
   display: flex;
   flex-direction: row; 
   align-items: center;
   justify-content: center;
 }

 .project-setting-property-container {
  height: 30px; 
 }

 #project-settings-modal-cancel {
   width: 80px; 
   height: 100%; 
 }
 
 #project-settings-modal-accept {
   width: 80px;
   height: 100%; 
   margin-left: 8px;
 } 
 