@import 'Editor/_wickbrand.scss';
@import '../_popoutcodeditor.scss';

$sub-tab-list-height: 40px;
$sub-tab-list-margin: 10px;
$add-script-container-height: calc(100% - 2 * #{$sub-tab-list-height} - #{$sub-tab-list-margin}); 
$tab-editor-left-padding: 15px;

.code-editor-tab-code-editor {
  height: 100%;
}

#add-scripts-panel-container {
  width: 100%;
  height: 100%; 
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $editor-popout-tertiary; 
}

#code-editor-add-script-tab {
  width: 15px;
  height: 15px; 
  display: flex; 
  align-items: center;
  justify-content: center;
}

#select-sub-tab-list {
  height: $sub-tab-list-height; 
  min-height: 40px; 
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: left;
  padding-left: $tab-editor-left-padding;
  overflow: auto; 
  margin-bottom: $sub-tab-list-margin;
}

.delete-tab-container {
  height: 17px;
  width: 17px; 
  margin-left: 4px; 
}