@import 'Editor/_wickbrand.scss';
@import '../_inspectorscriptwindow.scss';

.inspector-script-window-row-name {
    width: calc(100% - 50px - 10px); 
    height: 100%; 
    background-color: $editor-primary;
    color: $editor-primary-text;
    border-radius: 3px;
    overflow: hidden; 
    display: flex;
}

.inspector-script-window-row-edit {
    width: 25px;
    height: 100%; 
    background-color: $editor-primary;
    color: $editor-primary-text;
    border-radius: 3px;
}

.inspector-script-window-row-delete {
    width: 25px;
    height: 100%; 
    background-color: $editor-primary;
    color: $editor-primary-text;
    border-radius: 3px;
}

.inspector-script-window-row-color-bar {
    height: 100%;
    width: $script-window-margin;
    margin-right: $script-window-margin; 
}

.inspector-script-window-row-color-bar.blue-bar {
    background-color: $wick-active-blue;
}

.inspector-script-window-row-color-bar.green-bar {
    background-color: $wick-green;
}

.inspector-script-window-row-color-bar.yellow-bar {
    background-color: $wick-yellow;
}

