@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Abel);
@import url(https://fonts.googleapis.com/css?family=Allerta);
@import url(https://fonts.googleapis.com/css?family=Allerta+Stencil);
@import url(https://fonts.googleapis.com/css?family=Almendra+SC);
@import url(https://fonts.googleapis.com/css?family=Amatic+SC);
@import url(https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope);
@import url(https://fonts.googleapis.com/css?family=Antic+Didone);
@import url(https://fonts.googleapis.com/css?family=Antic+Slab);
@import url(https://fonts.googleapis.com/css?family=Anton);
@import url(https://fonts.googleapis.com/css?family=Architects+Daughter);
@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow);
@import url(https://fonts.googleapis.com/css?family=Arimo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Assistant);
@import url(https://fonts.googleapis.com/css?family=Average);
@import url(https://fonts.googleapis.com/css?family=Balthazar);
@import url(https://fonts.googleapis.com/css?family=Belgrano);
@import url(https://fonts.googleapis.com/css?family=Belleza);
@import url(https://fonts.googleapis.com/css?family=Bitter);
@import url(https://fonts.googleapis.com/css?family=Boogaloo);
@import url(https://fonts.googleapis.com/css?family=Bubbler+One);
@import url(https://fonts.googleapis.com/css?family=Cabin);
@import url(https://fonts.googleapis.com/css?family=Cagliostro);
@import url(https://fonts.googleapis.com/css?family=Cairo);
@import url(https://fonts.googleapis.com/css?family=Cambo);
@import url(https://fonts.googleapis.com/css?family=Candal);
@import url(https://fonts.googleapis.com/css?family=Catamaran);
@import url(https://fonts.googleapis.com/css?family=Ceviche+One);
@import url(https://fonts.googleapis.com/css?family=Chela+One);
@import url(https://fonts.googleapis.com/css?family=Cinzel);
@import url(https://fonts.googleapis.com/css?family=Contrail+One);
@import url(https://fonts.googleapis.com/css?family=Convergence);
@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
@import url(https://fonts.googleapis.com/css?family=Damion);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Domine);
@import url(https://fonts.googleapis.com/css?family=Dorsa);
@import url(https://fonts.googleapis.com/css?family=Dosis);
@import url(https://fonts.googleapis.com/css?family=Erica+One);
@import url(https://fonts.googleapis.com/css?family=Euphoria+Script);
@import url(https://fonts.googleapis.com/css?family=Fauna+One);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans+Condensed);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import url(https://fonts.googleapis.com/css?family=Francois+One);
@import url(https://fonts.googleapis.com/css?family=Fresca);
@import url(https://fonts.googleapis.com/css?family=Fugaz+One);
@import url(https://fonts.googleapis.com/css?family=Gafata);
@import url(https://fonts.googleapis.com/css?family=Galdeano);
@import url(https://fonts.googleapis.com/css?family=Geostar+Fill);
@import url(https://fonts.googleapis.com/css?family=Germania+One);
@import url(https://fonts.googleapis.com/css?family=Gilda+Display);
@import url(https://fonts.googleapis.com/css?family=Gloria+Hallelujah);
@import url(https://fonts.googleapis.com/css?family=Goblin+One);
@import url(https://fonts.googleapis.com/css?family=Gochi+Hand);
@import url(https://fonts.googleapis.com/css?family=Graduate);
@import url(https://fonts.googleapis.com/css?family=Habibi);
@import url(https://fonts.googleapis.com/css?family=Hind+Madurai);
@import url(https://fonts.googleapis.com/css?family=Hind+Siliguri);
@import url(https://fonts.googleapis.com/css?family=Holtwood+One+SC);
@import url(https://fonts.googleapis.com/css?family=Homenaje);
@import url(https://fonts.googleapis.com/css?family=Iceberg);
@import url(https://fonts.googleapis.com/css?family=Iceland);
@import url(https://fonts.googleapis.com/css?family=Imprima);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@import url(https://fonts.googleapis.com/css?family=Inder);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Italiana);
@import url(https://fonts.googleapis.com/css?family=Julee);
@import url(https://fonts.googleapis.com/css?family=Kanit);
@import url(https://fonts.googleapis.com/css?family=Keania+One);
@import url(https://fonts.googleapis.com/css?family=Kite+One);
@import url(https://fonts.googleapis.com/css?family=Knewave);
@import url(https://fonts.googleapis.com/css?family=Kotta+One);
@import url(https://fonts.googleapis.com/css?family=Krona+One);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lemon);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+128);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Text);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin);
@import url(https://fonts.googleapis.com/css?family=Lilita+One);
@import url(https://fonts.googleapis.com/css?family=Lily+Script+One);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Loved+by+the+King);
@import url(https://fonts.googleapis.com/css?family=Lustria);
@import url(https://fonts.googleapis.com/css?family=Macondo+Swash+Caps);
@import url(https://fonts.googleapis.com/css?family=Marko+One);
@import url(https://fonts.googleapis.com/css?family=Mate+SC);
@import url(https://fonts.googleapis.com/css?family=Medula+One);
@import url(https://fonts.googleapis.com/css?family=Merienda+One);
@import url(https://fonts.googleapis.com/css?family=Montaga);
@import url(https://fonts.googleapis.com/css?family=Mr+Bedfort);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=News+Cycle);
@import url(https://fonts.googleapis.com/css?family=Nothing+You+Could+Do);
@import url(https://fonts.googleapis.com/css?family=Noticia+Text);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(https://fonts.googleapis.com/css?family=Numans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Offside);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Overlock+SC);
@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Passero+One);
@import url(https://fonts.googleapis.com/css?family=Pathway+Gothic+One);
@import url(https://fonts.googleapis.com/css?family=Patua+One);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Plaster);
@import url(https://fonts.googleapis.com/css?family=Play);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Poller+One);
@import url(https://fonts.googleapis.com/css?family=Pompiere);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Port+Lligat+Sans);
@import url(https://fonts.googleapis.com/css?family=Port+Lligat+Slab);
@import url(https://fonts.googleapis.com/css?family=Prociono);
@import url(https://fonts.googleapis.com/css?family=Questrial);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Rammetto+One);
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
@import url(https://fonts.googleapis.com/css?family=Rochester);
@import url(https://fonts.googleapis.com/css?family=Ropa+Sans);
@import url(https://fonts.googleapis.com/css?family=Ruluko);
@import url(https://fonts.googleapis.com/css?family=Sail);
@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light);
@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two);
@import url(https://fonts.googleapis.com/css?family=Slabo+27px);
@import url(https://fonts.googleapis.com/css?family=Slackey);
@import url(https://fonts.googleapis.com/css?family=Sofia);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro);
@import url(https://fonts.googleapis.com/css?family=Spinnaker);
@import url(https://fonts.googleapis.com/css?family=Squada+One);
@import url(https://fonts.googleapis.com/css?family=Strait);
@import url(https://fonts.googleapis.com/css?family=Tauri);
@import url(https://fonts.googleapis.com/css?family=Telex);
@import url(https://fonts.googleapis.com/css?family=Text+Me+One);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
@import url(https://fonts.googleapis.com/css?family=Trykker);
@import url(https://fonts.googleapis.com/css?family=Tulpen+One);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Condensed);
@import url(https://fonts.googleapis.com/css?family=UnifrakturCook);
@import url(https://fonts.googleapis.com/css?family=Unlock);
@import url(https://fonts.googleapis.com/css?family=Vampiro+One);
@import url(https://fonts.googleapis.com/css?family=Viga);
@import url(https://fonts.googleapis.com/css?family=Vollkorn);
@import url(https://fonts.googleapis.com/css?family=Voltaire);
@import url(https://fonts.googleapis.com/css?family=Wendy+One);
@import url(https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

body {
  overflow: hidden;
  text-rendering: optimizeLegibility; }

#toolbox-container {
  height: 40px; }

#editor-canvas-timeline-panel {
  height: calc(100% - 40px); }

#editor {
  width: 100%;
  height: 100%;
  background-color: red; }

#menu-bar-container {
  width: 100%;
  height: 40px;
  background-color: green; }

#editor-body {
  width: 100%;
  height: calc(100% - 40px);
  background-color: blue;
  overflow: hidden; }

#flexible-container {
  height: calc(100% - 40px);
  width: 100%;
  background-color: #AAA;
  display: inline-block;
  position: absolute;
  top: 40px; }

.center-container {
  background-color: #ffaabb; }

.right-container {
  background-color: #aabbcc; }

.right-reflex-container {
  height: 100%;
  background-color: #aabbcc; }

.Toastify__toast-container--top-right {
  top: 40px;
  right: 1em; }

.info-toast-background {
  background-color: #3D3B3B; }

.error-toast-background {
  background-color: #E61E07; }

.warning-toast-background {
  background-color: #E9AA02; }

.success-toast-background {
  background-color: #01C094; }

/* Basic re-flex panel/splitter styling */
.reflex-container {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align items in Main Axis */
  -webkit-align-items: stretch;
          align-items: stretch;
  /* align items in Cross Axis */
  -webkit-align-content: stretch;
          align-content: stretch;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%; }

.reflex-container.horizontal {
  -webkit-flex-direction: column;
          flex-direction: column; }

.reflex-container.vertical {
  -webkit-flex-direction: row;
          flex-direction: row; }

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%; }

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%; }

.reflex-container > .reflex-splitter {
  background-color: #191919;
  z-index: 0; }

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: #AFAFAF;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.horizontal > .reflex-splitter {
  border-bottom: calc(4px / 2) solid #191919 !important;
  border-top: calc(4px / 2) solid #191919 !important;
  cursor: row-resize;
  width: 100%;
  height: 2px; }

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: calc(4px / 2) solid #AFAFAF !important;
  border-top: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container.vertical > .reflex-splitter {
  border-right: calc(4px / 2) solid #191919 !important;
  border-left: calc(4px / 2) solid #191919 !important;
  cursor: col-resize;
  height: 100%;
  width: 2px; }

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: calc(4px / 2) solid #AFAFAF !important;
  border-left: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 0; }

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  -webkit-transition: all 1.5s ease;
  transition: all 1.5s ease;
  opacity: 0.5; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  border-top: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  height: 17px !important;
  cursor: row-resize;
  margin: -calc(4px / 2) 0;
  width: 100%; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: calc(4px / 2) solid #AFAFAF !important;
  border-top: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  border-left: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  width: 17px !important;
  cursor: col-resize;
  margin: 0-calc(4px / 2);
  height: 100%; }

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: calc(4px / 2) solid #AFAFAF !important;
  border-left: calc(4px / 2) solid #AFAFAF !important; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.docked-panel {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 100%;
  overflow: hidden; }

.docked-panel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .5;
  z-index: 1; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.wick-input-container {
  width: 100%;
  height: 100%; }

.wick-input {
  padding: 2px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  background-color: #D8D8D8;
  color: #000000; }

.wick-input-invalid {
  border: 3px solid #FD384C !important; }

.wick-input-updating {
  border: 3px solid #FFC835 !important; }

.wick-numeric-input {
  padding: 2px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  background-color: #303030;
  color: #AFAFAF;
  border: 3px solid #191919;
  font-size: 14px; }

.wick-input.read-only {
  background-color: gray; }

.wick-slider {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: auto; }

.wick-checkbox {
  height: 100%;
  width: 100%; }

.wick-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  padding: 2px; }

.wick-slider {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.wick-select {
  width: 100%;
  height: 100%; }

.wick-select-control {
  padding: 0 !important;
  padding-left: 2px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 26px; }

.wick-select-menu {
  background-color: #EAEAEA;
  color: #1D1921; }

.Dropdown-option {
  padding: 2px !important;
  white-space: nowrap;
  background-color: #EAEAEA;
  color: #1D1921; }

.Dropdown-option.is-selected {
  background-color: #29F1A3 !important;
  color: #1D1921 !important; }

.Dropdown-placeholder {
  white-space: nowrap;
  max-width: 110px; }

.wick-tooltip {
  max-width: 220px;
  opacity: .9 !important; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.btn-color-picker {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 4px solid #4A4A4A; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.img-tool-icon {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.action-button {
  width: 100%;
  height: 100%; }

.action-button-text {
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.action-button-blue {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #101DA5;
  color: white; }

.action-button-blue:hover {
  background-color: #2636E1;
  color: white; }

.action-button-blue:active {
  background-color: #010A6F;
  color: white; }

.action-button-blue.active-button {
  background-color: #010A6F;
  color: white; }

.action-button-sky {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #8DDED6;
  color: black; }

.action-button-sky:hover {
  background-color: #B4ECE6;
  color: black; }

.action-button-sky:active {
  background-color: #D3F8F4;
  color: black; }

.action-button-sky.active-button {
  background-color: #D3F8F4;
  color: black; }

.action-button-red {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #FD384C;
  color: white; }

.action-button-red:hover {
  background-color: #FF5372;
  color: white; }

.action-button-red:active {
  background-color: #E61E07;
  color: white; }

.action-button-red.active-button {
  background-color: #E61E07;
  color: white; }

.action-button-green {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #1EE29A;
  color: white; }

.action-button-green:hover {
  background-color: #29F1A3;
  color: white; }

.action-button-green:active {
  background-color: #01C094;
  color: white; }

.action-button-green.active-button {
  background-color: #01C094;
  color: white; }

.action-button-yellow {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #FFC835;
  color: black; }

.action-button-yellow:hover {
  background-color: #FFE243;
  color: black; }

.action-button-yellow:active {
  background-color: #E9AA02;
  color: black; }

.action-button-yellow.active-button {
  background-color: #E9AA02;
  color: black; }

.action-button-tool {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: black; }

.action-button-tool:hover {
  background-color: #29F1A3;
  color: black; }

.action-button-tool:active {
  background-color: #01C094;
  color: black; }

.action-button-tool.active-button {
  background-color: #01C094;
  color: black; }

.action-button-menu {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #BABABA; }

.action-button-menu:hover {
  color: #DADADA; }

.action-button-menu:active {
  color: #DADADA; }

.action-button-menu.active-button {
  color: #DADADA; }

.action-button-menu.save {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #1EE29A;
  border: 2px solid #1EE29A; }

.action-button-tool-settings {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #3B3B3B;
  color: #797979; }

.action-button-tool-settings:hover {
  background-color: #4A4A4A;
  color: #AFAFAF; }

.action-button-tool-settings:active {
  background-color: #4A4A4A; }

.action-button-tool-settings.active-button {
  background-color: #4A4A4A; }

.action-button-upload {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #303030;
  color: #AFAFAF;
  border: 2px solid #AFAFAF;
  font-size: 16px; }

.action-button-upload:hover {
  background-color: #29F1A3;
  color: #01C094;
  border: 2px solid #29F1A3; }

.action-button-upload:active {
  background-color: #1EE29A;
  color: #01C094;
  border: 2px solid #1EE29A; }

.action-button-upload.active-button {
  background-color: #29F1A3;
  color: #01C094;
  border: 2px solid #29F1A3; }

.action-button-gray {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #4F4F4F;
  color: #848484;
  font-size: 16px; }

.action-button-gray:hover {
  background-color: #5D5D5D;
  color: #848484; }

.action-button-gray:active {
  background-color: #424242;
  color: #848484; }

.action-button-gray.active-button {
  background-color: #424242;
  color: #848484; }

.action-button-gray-green {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #4F4F4F;
  color: white;
  font-size: 16px; }

.action-button-gray-green:hover {
  background-color: #1EE29A;
  color: white; }

.action-button-gray-green:active {
  background-color: #01C094;
  color: white; }

.action-button-gray-green.active-button {
  background-color: #01C094;
  color: white; }

.action-button-error {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: transparent;
  color: white;
  font-size: 16px; }

.action-button-error:hover {
  background-color: #FF7867;
  color: white; }

.action-button-error:active {
  background-color: #8D564D;
  color: white; }

.action-button-error.active-button {
  background-color: #8D564D;
  color: white; }

.action-button-checkbox {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #3B3B3B;
  color: black; }

.action-button-checkbox:hover {
  background-color: #4A4A4A;
  color: black; }

.action-button-checkbox:active {
  background-color: #01C094;
  color: black; }

.action-button-checkbox.active-button {
  background-color: #01C094;
  color: black; }

.action-button-inspector {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #00ADEF;
  color: white;
  font-size: 16px; }

.action-button-inspector:hover {
  background-color: #00B9FF;
  color: white; }

.action-button-inspector:active {
  background-color: #00709c;
  color: white; }

.action-button-inspector.active-button {
  background-color: #00709c;
  color: white; }

.action-button-reference {
  -webkit-transition-duration: 0.2s;
  /* Safari */
  transition-duration: 0.2s;
  background-color: #353434;
  color: #B1B1B1;
  font-size: 16px; }

.action-button-reference:hover {
  background-color: #00ADEF;
  color: white; }

.action-button-reference:active {
  background-color: #00709c;
  color: white; }

.action-button-reference.active-button {
  background-color: #00709c;
  color: white; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#modal-close-icon-container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

#modal-close-icon-container:hover {
  cursor: pointer; }

#modal-icon-container {
  width: 60px;
  height: 60px; }

#modal-modal-icon {
  width: 100%;
  height: 100%; }

.modal-generic-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.project-settings-modal-body {
  width: 240px;
  min-width: 240px;
  height: 370px;
  padding: 20px; }

#project-settings-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center; }

.project-settings-property-label {
  color: #E6E6E6; }

.project-settings-size-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.project-settings-split {
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: center;
  text-align: center; }

.project-setting-container {
  margin-top: 8px; }

.project-settings-size-input {
  float: right; }

#project-settings-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#project-settings-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.project-setting-property-container {
  height: 30px; }

#project-settings-modal-cancel {
  width: 80px;
  height: 100%; }

#project-settings-modal-accept {
  width: 80px;
  height: 100%;
  margin-left: 8px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.object-info-title {
  color: #E6E6E6;
  font-size: 16px; }

.object-info-row {
  color: #E6E6E6;
  font-size: 14px;
  margin-top: 3px;
  display: -webkit-flex;
  display: flex; }

.object-info-row-icon {
  float: left;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  left: 0; }

.object-info-row-text {
  margin-left: 4px;
  color: #b6b6b6; }

.object-info-container {
  width: 100%;
  height: 140px;
  background-color: #525252;
  border-radius: 3px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 10px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.make-interactive-modal-body {
  width: 450px;
  min-width: 240px;
  height: 320px;
  padding: 20px; }

#make-interactive-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#make-interactive-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#make-interactive-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

#make-interactive-modal-footer > div:last-child {
  margin-left: 12px; }

#make-interactive-asset-checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left; }

.make-interactive-asset-checkbox-input-container {
  width: 20px;
  height: 20px; }

#make-interactive-asset-checkbox-container {
  margin-top: 10px;
  width: 100%; }

#make-interactive-asset-checkbox-message {
  margin-left: 10px;
  color: #E6E6E6; }

#make-interactive-modal-name-input {
  margin-top: 10px;
  width: 100%; }

.make-interactive-object-info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.make-interactive-object-info-container > div:last-child {
  margin-left: 12px; }

.make-interactive-modal-button {
  width: 50%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.autosave-modal-body {
  width: 240px;
  min-width: 240px;
  height: 250px;
  padding: 20px; }

#autosave-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center;
  margin-top: 20px; }

#autosave-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#autosave-modal-message {
  width: 100%;
  color: #E6E6E6;
  text-align: center;
  font-size: 14px; }

#autosave-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#autosave-modal-cancel {
  width: 80px;
  height: 100%; }

#autosave-modal-accept {
  width: 80px;
  height: 100%;
  margin-left: 8px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.welcome-modal-body {
  width: 670px;
  min-width: 670px;
  height: 420px;
  padding: 0px; }

#welcome-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100%; }

#welcome-image-container {
  width: 320px;
  min-width: 320px; }

#welcome-image {
  width: 100%;
  height: 100%; }

#welcome-message-container {
  background-color: #1E1E1E;
  width: 350px;
  min-width: 350px;
  padding: 28px;
  padding-top: 30px;
  position: relative; }

#welcome-modal-title {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap; }

#welcome-modal-version {
  color: #E6E6E6;
  font-size: 16px;
  font-weight: 600; }

#welcome-modal-subtitle {
  color: #FD384C;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px; }

#welcome-modal-message {
  margin-top: 30px;
  color: #E6E6E6;
  font-size: 22px; }

#welcome-modal-forum-link {
  color: #E6E6E6;
  font-size: 14px;
  position: absolute;
  left: 28px;
  bottom: calc(28px + 40px); }

.welcome-modal-highlight {
  color: #1EE29A; }

.welcome-modal-main-container {
  height: 100%; }

#welcome-modal-footer {
  width: calc(100% - calc(2 * 28px));
  height: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 28px; }

#welcome-modal-cancel {
  width: 80px;
  height: 100%;
  margin-left: auto; }

#welcome-modal-accept {
  width: 65px;
  height: 100%;
  margin-left: 8px; }

#welcome-modal-tutorial-links {
  display: -webkit-flex;
  display: flex; }

.welcome-link {
  margin-left: 12px; }

.welcome-link:first-child {
  margin-left: 0px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.make-animated-modal-body {
  width: 240px;
  min-width: 240px;
  height: 330px;
  padding: 20px; }

.make-animated-message-modal-button {
  font-family: Nunito; }

#make-animated-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#make-animated-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#make-animated-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#make-animated-modal-accept {
  width: 100%;
  height: 100%; }

#make-animated-asset-checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left; }

.make-animated-asset-checkbox-input-container {
  width: 20px;
  height: 20px; }

#make-animated-asset-checkbox-container {
  margin-top: 10px;
  width: 100%; }

#make-animated-asset-checkbox-message {
  margin-left: 10px;
  color: #E6E6E6; }

#make-animated-modal-name-input {
  margin-top: 10px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.select-sub-tab {
  margin-left: 15px;
  border-bottom: 2px solid #353434;
  color: #919191;
  font-size: 16px;
  cursor: pointer; }

.select-sub-tab:first-child {
  margin-left: 0px; }

.select-sub-tab.selected {
  -webkit-transition: .4s;
  transition: .4s;
  border-bottom: 2px solid #1EE29A;
  color: white; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.export-modal-body {
  width: 450px;
  min-width: 240px;
  height: 320px;
  padding: 20px; }

#export-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#export-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#export-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

#export-modal-footer > div:last-child {
  margin-left: 12px; }

#export-modal-name-input {
  margin-top: 10px;
  width: 100%; }

.export-info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%; }

.export-info-container > div:last-child {
  margin-left: 12px; }

.export-modal-button {
  width: 50%; }

.export-object-info {
  height: 120px; }

.export-object-info-off {
  background-color: #222222; }

#export-sub-tab-list {
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: left;
          justify-content: left;
  overflow: auto; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.general-warning-modal-body {
  width: 240px;
  min-width: 240px;
  height: 250px;
  padding: 20px; }

#general-warning-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: center;
  margin-top: 20px; }

#general-warning-modal-interior-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#general-warning-modal-message {
  width: 100%;
  color: #E6E6E6;
  text-align: center;
  font-size: 14px; }

#general-warning-modal-footer {
  width: 100%;
  height: 28px;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#general-warning-modal-cancel {
  width: 80px;
  height: 100%; }

#general-warning-modal-accept {
  width: 80px;
  height: 100%;
  margin-left: 8px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.media-export-modal-body {
  width: 300px;
  min-width: 240px;
  height: 160px;
  padding: 20px; }

.media-export-modal-content {
  padding-top: 15px;
  width: 100%;
  height: 100%; }

#media-export-modal-title {
  font-size: 18px;
  color: #E6E6E6;
  font-weight: bold;
  text-align: left;
  width: 100%; }

#media-export-modal-subtitle {
  font-size: 16px;
  color: #E6E6E6;
  padding-bottom: 4px; }

#media-export-modal-status-message {
  font-size: 14px;
  color: #E6E6E6; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#wick-canvas-container {
  width: 100%;
  height: 100%;
  background-color: #bbb;
  position: relative; }

#canvas-container-wrapper {
  border-left: 4px solid #191919; }

canvas[resize] {
  width: 100%;
  height: 100%; }

.drag-drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  z-index: 1;
  background-color: #EAEAEA; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.font-selector-Abel {
  font-family: 'Abel','Arial'; }

.font-selector-Abril-Fatface {
  font-family: 'Abril Fatface','Arial'; }

.font-selector-Allerta {
  font-family: 'Allerta','Arial'; }

.font-selector-Allerta-Stencil {
  font-family: 'Allerta Stencil','Arial'; }

.font-selector-Almendra-SC {
  font-family: 'Almendra SC','Arial'; }

.font-selector-Amatic-SC {
  font-family: 'Amatic SC','Arial'; }

.font-selector-Annie-Use-Your-Telescope {
  font-family: 'Annie Use Your Telescope','Arial'; }

.font-selector-Antic-Didone {
  font-family: 'Antic Didone','Arial'; }

.font-selector-Antic-Slab {
  font-family: 'Antic Slab','Arial'; }

.font-selector-Anton {
  font-family: 'Anton','Arial'; }

.font-selector-Architects-Daughter {
  font-family: 'Architects Daughter','Arial'; }

.font-selector-Archivo-Narrow {
  font-family: 'Archivo Narrow','Arial'; }

.font-selector-Arimo {
  font-family: 'Arimo','Arial'; }

.font-selector-Arvo {
  font-family: 'Arvo','Arial'; }

.font-selector-Assistant {
  font-family: 'Assistant','Arial'; }

.font-selector-Average {
  font-family: 'Average','Arial'; }

.font-selector-Balthazar {
  font-family: 'Balthazar','Arial'; }

.font-selector-Belgrano {
  font-family: 'Belgrano','Arial'; }

.font-selector-Belleza {
  font-family: 'Belleza','Arial'; }

.font-selector-Bitter {
  font-family: 'Bitter','Arial'; }

.font-selector-Boogaloo {
  font-family: 'Boogaloo','Arial'; }

.font-selector-Bubbler-One {
  font-family: 'Bubbler One','Arial'; }

.font-selector-Cabin {
  font-family: 'Cabin','Arial'; }

.font-selector-Cagliostro {
  font-family: 'Cagliostro','Arial'; }

.font-selector-Cairo {
  font-family: 'Cairo','Arial'; }

.font-selector-Cambo {
  font-family: 'Cambo','Arial'; }

.font-selector-Candal {
  font-family: 'Candal','Arial'; }

.font-selector-Catamaran {
  font-family: 'Catamaran','Arial'; }

.font-selector-Ceviche-One {
  font-family: 'Ceviche One','Arial'; }

.font-selector-Chela-One {
  font-family: 'Chela One','Arial'; }

.font-selector-Cinzel {
  font-family: 'Cinzel','Arial'; }

.font-selector-Contrail-One {
  font-family: 'Contrail One','Arial'; }

.font-selector-Convergence {
  font-family: 'Convergence','Arial'; }

.font-selector-Crimson-Text {
  font-family: 'Crimson Text','Arial'; }

.font-selector-Damion {
  font-family: 'Damion','Arial'; }

.font-selector-Dancing-Script {
  font-family: 'Dancing Script','Arial'; }

.font-selector-Domine {
  font-family: 'Domine','Arial'; }

.font-selector-Dorsa {
  font-family: 'Dorsa','Arial'; }

.font-selector-Dosis {
  font-family: 'Dosis','Arial'; }

.font-selector-Erica-One {
  font-family: 'Erica One','Arial'; }

.font-selector-Euphoria-Script {
  font-family: 'Euphoria Script','Arial'; }

.font-selector-Fauna-One {
  font-family: 'Fauna One','Arial'; }

.font-selector-Fira-Sans-Condensed {
  font-family: 'Fira Sans Condensed','Arial'; }

.font-selector-Fjalla-One {
  font-family: 'Fjalla One','Arial'; }

.font-selector-Francois-One {
  font-family: 'Francois One','Arial'; }

.font-selector-Fresca {
  font-family: 'Fresca','Arial'; }

.font-selector-Fugaz-One {
  font-family: 'Fugaz One','Arial'; }

.font-selector-Gafata {
  font-family: 'Gafata','Arial'; }

.font-selector-Galdeano {
  font-family: 'Galdeano','Arial'; }

.font-selector-Geostar-Fill {
  font-family: 'Geostar Fill','Arial'; }

.font-selector-Germania-One {
  font-family: 'Germania One','Arial'; }

.font-selector-Gilda-Display {
  font-family: 'Gilda Display','Arial'; }

.font-selector-Gloria-Hallelujah {
  font-family: 'Gloria Hallelujah','Arial'; }

.font-selector-Goblin-One {
  font-family: 'Goblin One','Arial'; }

.font-selector-Gochi-Hand {
  font-family: 'Gochi Hand','Arial'; }

.font-selector-Graduate {
  font-family: 'Graduate','Arial'; }

.font-selector-Habibi {
  font-family: 'Habibi','Arial'; }

.font-selector-Hind-Madurai {
  font-family: 'Hind Madurai','Arial'; }

.font-selector-Hind-Siliguri {
  font-family: 'Hind Siliguri','Arial'; }

.font-selector-Holtwood-One-SC {
  font-family: 'Holtwood One SC','Arial'; }

.font-selector-Homenaje {
  font-family: 'Homenaje','Arial'; }

.font-selector-Iceberg {
  font-family: 'Iceberg','Arial'; }

.font-selector-Iceland {
  font-family: 'Iceland','Arial'; }

.font-selector-Imprima {
  font-family: 'Imprima','Arial'; }

.font-selector-Inconsolata {
  font-family: 'Inconsolata','Arial'; }

.font-selector-Inder {
  font-family: 'Inder','Arial'; }

.font-selector-Indie-Flower {
  font-family: 'Indie Flower','Arial'; }

.font-selector-Italiana {
  font-family: 'Italiana','Arial'; }

.font-selector-Julee {
  font-family: 'Julee','Arial'; }

.font-selector-Kanit {
  font-family: 'Kanit','Arial'; }

.font-selector-Keania-One {
  font-family: 'Keania One','Arial'; }

.font-selector-Kite-One {
  font-family: 'Kite One','Arial'; }

.font-selector-Knewave {
  font-family: 'Knewave','Arial'; }

.font-selector-Kotta-One {
  font-family: 'Kotta One','Arial'; }

.font-selector-Krona-One {
  font-family: 'Krona One','Arial'; }

.font-selector-Lato {
  font-family: 'Lato','Arial'; }

.font-selector-Lemon {
  font-family: 'Lemon','Arial'; }

.font-selector-Libre-Barcode-128 {
  font-family: 'Libre Barcode 128','Arial'; }

.font-selector-Libre-Barcode-39 {
  font-family: 'Libre Barcode 39','Arial'; }

.font-selector-Libre-Barcode-39-Extended {
  font-family: 'Libre Barcode 39 Extended','Arial'; }

.font-selector-Libre-Barcode-39-Extended-Text {
  font-family: 'Libre Barcode 39 Extended Text','Arial'; }

.font-selector-Libre-Barcode-39-Text {
  font-family: 'Libre Barcode 39 Text','Arial'; }

.font-selector-Libre-Franklin {
  font-family: 'Libre Franklin','Arial'; }

.font-selector-Lilita-One {
  font-family: 'Lilita One','Arial'; }

.font-selector-Lily-Script-One {
  font-family: 'Lily Script One','Arial'; }

.font-selector-Lobster {
  font-family: 'Lobster','Arial'; }

.font-selector-Loved-by-the-King {
  font-family: 'Loved by the King','Arial'; }

.font-selector-Lustria {
  font-family: 'Lustria','Arial'; }

.font-selector-Macondo-Swash-Caps {
  font-family: 'Macondo Swash Caps','Arial'; }

.font-selector-Marko-One {
  font-family: 'Marko One','Arial'; }

.font-selector-Mate-SC {
  font-family: 'Mate SC','Arial'; }

.font-selector-Medula-One {
  font-family: 'Medula One','Arial'; }

.font-selector-Merienda-One {
  font-family: 'Merienda One','Arial'; }

.font-selector-Montaga {
  font-family: 'Montaga','Arial'; }

.font-selector-Mr-Bedfort {
  font-family: 'Mr Bedfort','Arial'; }

.font-selector-Mukta {
  font-family: 'Mukta','Arial'; }

.font-selector-News-Cycle {
  font-family: 'News Cycle','Arial'; }

.font-selector-Nothing-You-Could-Do {
  font-family: 'Nothing You Could Do','Arial'; }

.font-selector-Noticia-Text {
  font-family: 'Noticia Text','Arial'; }

.font-selector-Noto-Sans {
  font-family: 'Noto Sans','Arial'; }

.font-selector-Numans {
  font-family: 'Numans','Arial'; }

.font-selector-Nunito {
  font-family: 'Nunito','Arial'; }

.font-selector-Nunito-Sans {
  font-family: 'Nunito Sans','Arial'; }

.font-selector-Offside {
  font-family: 'Offside','Arial'; }

.font-selector-Open-Sans {
  font-family: 'Open Sans','Arial'; }

.font-selector-Oswald {
  font-family: 'Oswald','Arial'; }

.font-selector-Overlock-SC {
  font-family: 'Overlock SC','Arial'; }

.font-selector-Pacifico {
  font-family: 'Pacifico','Arial'; }

.font-selector-Passero-One {
  font-family: 'Passero One','Arial'; }

.font-selector-Pathway-Gothic-One {
  font-family: 'Pathway Gothic One','Arial'; }

.font-selector-Patua-One {
  font-family: 'Patua One','Arial'; }

.font-selector-Permanent-Marker {
  font-family: 'Permanent Marker','Arial'; }

.font-selector-Plaster {
  font-family: 'Plaster','Arial'; }

.font-selector-Play {
  font-family: 'Play','Arial'; }

.font-selector-Playfair-Display {
  font-family: 'Playfair Display','Arial'; }

.font-selector-Poller-One {
  font-family: 'Poller One','Arial'; }

.font-selector-Pompiere {
  font-family: 'Pompiere','Arial'; }

.font-selector-Poppins {
  font-family: 'Poppins','Arial'; }

.font-selector-Port-Lligat-Sans {
  font-family: 'Port Lligat Sans','Arial'; }

.font-selector-Port-Lligat-Slab {
  font-family: 'Port Lligat Slab','Arial'; }

.font-selector-Prociono {
  font-family: 'Prociono','Arial'; }

.font-selector-Questrial {
  font-family: 'Questrial','Arial'; }

.font-selector-Quicksand {
  font-family: 'Quicksand','Arial'; }

.font-selector-Raleway {
  font-family: 'Raleway','Arial'; }

.font-selector-Rammetto-One {
  font-family: 'Rammetto One','Arial'; }

.font-selector-Righteous {
  font-family: 'Righteous','Arial'; }

.font-selector-Roboto {
  font-family: 'Roboto','Arial'; }

.font-selector-Roboto-Mono {
  font-family: 'Roboto Mono','Arial'; }

.font-selector-Rochester {
  font-family: 'Rochester','Arial'; }

.font-selector-Ropa-Sans {
  font-family: 'Ropa Sans','Arial'; }

.font-selector-Ruluko {
  font-family: 'Ruluko','Arial'; }

.font-selector-Sail {
  font-family: 'Sail','Arial'; }

.font-selector-Shadows-Into-Light {
  font-family: 'Shadows Into Light','Arial'; }

.font-selector-Shadows-Into-Light-Two {
  font-family: 'Shadows Into Light Two','Arial'; }

.font-selector-Slabo-27px {
  font-family: 'Slabo 27px','Arial'; }

.font-selector-Slackey {
  font-family: 'Slackey','Arial'; }

.font-selector-Sofia {
  font-family: 'Sofia','Arial'; }

.font-selector-Source-Code-Pro {
  font-family: 'Source Code Pro','Arial'; }

.font-selector-Source-Sans-Pro {
  font-family: 'Source Sans Pro','Arial'; }

.font-selector-Source-Serif-Pro {
  font-family: 'Source Serif Pro','Arial'; }

.font-selector-Spinnaker {
  font-family: 'Spinnaker','Arial'; }

.font-selector-Squada-One {
  font-family: 'Squada One','Arial'; }

.font-selector-Strait {
  font-family: 'Strait','Arial'; }

.font-selector-Tauri {
  font-family: 'Tauri','Arial'; }

.font-selector-Telex {
  font-family: 'Telex','Arial'; }

.font-selector-Text-Me-One {
  font-family: 'Text Me One','Arial'; }

.font-selector-Titillium-Web {
  font-family: 'Titillium Web','Arial'; }

.font-selector-Trykker {
  font-family: 'Trykker','Arial'; }

.font-selector-Tulpen-One {
  font-family: 'Tulpen One','Arial'; }

.font-selector-Ubuntu-Condensed {
  font-family: 'Ubuntu Condensed','Arial'; }

.font-selector-UnifrakturCook {
  font-family: 'UnifrakturCook','Arial'; }

.font-selector-Unlock {
  font-family: 'Unlock','Arial'; }

.font-selector-Vampiro-One {
  font-family: 'Vampiro One','Arial'; }

.font-selector-Viga {
  font-family: 'Viga','Arial'; }

.font-selector-Vollkorn {
  font-family: 'Vollkorn','Arial'; }

.font-selector-Voltaire {
  font-family: 'Voltaire','Arial'; }

.font-selector-Wendy-One {
  font-family: 'Wendy One','Arial'; }

.font-selector-Yanone-Kaffeesatz {
  font-family: 'Yanone Kaffeesatz','Arial'; }

.existing-font {
  background-color: #D3F8F4; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-title {
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.inspector-title-container {
  box-shadow: 0px 2px 4px black;
  position: relative; }

.inspector-title-name {
  font-size: 22px;
  color: #797979;
  font-family: 'Nunito Sans';
  display: inline-block; }

.inspector-title-selection-type {
  height: 80%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #AFAFAF;
  margin-left: 8px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-row-identifier {
  width: 175px;
  max-width: 175px;
  height: 100%;
  color: #AFAFAF;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold; }

.inspector-row-identifier:hover {
  overflow: auto; }

.inspector-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 26px;
  margin-bottom: 4px; }

.inspector-row:last-child {
  margin-bottom: 0px; }

.inspector-preview-row {
  height: 100px; }

.inspector-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 90%;
  margin-left: 4px; }

.inspector-input-element {
  display: inline-block;
  height: 100%; }

.single-input-element {
  width: 100%; }

.double-input-element {
  width: 40%; }

.input-divider {
  color: #FFFFFF;
  width: 20%;
  text-align: center; }

.double-input {
  height: 100%;
  display: -webkit-flex;
  display: flex; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-button {
  height: 40px;
  width: 100%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-image-preview {
  height: 100px;
  width: auto; }

.inspector-image-preview-container {
  width: 100%;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-script-window-row-container {
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px; }

.inspector-script-window-container {
  width: 100%;
  height: 100px;
  background-color: #4A4A4A;
  border-radius: 3px; }

.inspector-script-window-container {
  height: 100%;
  padding-bottom: 5px; }

.inspector-script-window-header {
  width: 100%;
  height: 25px;
  background-color: #3B3B3B;
  color: #797979;
  text-align: center;
  vertical-align: center;
  border-radius: 3px 3px 0px 0px; }

.inspector-add-script-button {
  height: 25px;
  width: 100%; }

.inspector-script-window-row-name {
  width: calc(100% - 50px - 10px);
  height: 100%;
  background-color: #303030;
  color: #AFAFAF;
  border-radius: 3px;
  overflow: hidden;
  display: -webkit-flex;
  display: flex; }

.inspector-script-window-row-edit {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: #AFAFAF;
  border-radius: 3px; }

.inspector-script-window-row-delete {
  width: 25px;
  height: 100%;
  background-color: #303030;
  color: #AFAFAF;
  border-radius: 3px; }

.inspector-script-window-row-color-bar {
  height: 100%;
  width: 5px;
  margin-right: 5px; }

.inspector-script-window-row-color-bar.blue-bar {
  background-color: #00ADEF; }

.inspector-script-window-row-color-bar.green-bar {
  background-color: #1EE29A; }

.inspector-script-window-row-color-bar.yellow-bar {
  background-color: #FFC835; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.inspector {
  background-color: #303030;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border-right: 4px solid #191919;
  font-family: 'Nunito Sans';
  overflow: hidden; }

.inspector-body {
  height: calc(100% - 36px);
  width: 100%;
  overflow: hidden; }

.inspector-body:hover {
  overflow-y: auto; }

.input-divider {
  color: #191919;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Nunito Sans';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.inspector-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: calc(10px / 2);
  padding-bottom: calc(10px / 2);
  border-bottom: calc(4px * .5) solid #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.select-inspector {
  height: 20px; }

.inspector-action-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px; }

.inspector-script-window-row-container {
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px; }

.inspector-script-window-container {
  width: 100%;
  height: 100px;
  background-color: #4A4A4A;
  border-radius: 3px; }

.inspector-script-window-container {
  height: 100%;
  padding-bottom: 5px; }

.inspector-script-window-header {
  width: 100%;
  height: 25px;
  background-color: #3B3B3B;
  color: #797979;
  text-align: center;
  vertical-align: center;
  border-radius: 3px 3px 0px 0px; }

.inspector-add-script-button {
  height: 25px;
  width: 100%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.menu-bar {
  height: 100%;
  width: 100%;
  background-color: #303030;
  overflow: visible;
  border: 4px solid #191919;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  -webkit-align-items: center;
          align-items: center; }

.menu-bar-project-name {
  -webkit-transition: .4s;
  transition: .4s;
  font-size: 18px;
  font-family: 'Nunito Sans';
  float: left;
  margin: 4px;
  margin-left: 8px;
  color: #DADADA;
  cursor: pointer; }

.menu-bar-project-name:hover {
  color: #BABABA; }

.menu-bar-actions-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  font-family: 'Nunito Sans';
  float: right;
  position: absolute;
  right: 0;
  height: calc(100% - 2 * 4px); }

.project-settings-preview {
  height: 70%;
  padding-left: 8px;
  padding-right: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.menu-bar-button {
  width: 55px;
  height: 80%;
  float: left;
  padding-right: 8px;
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

body {
  overflow: hidden;
  text-rendering: optimizeLegibility; }

#toolbox-container {
  height: 40px; }

#editor-canvas-timeline-panel {
  height: calc(100% - 40px); }

#editor {
  width: 100%;
  height: 100%;
  background-color: red; }

#menu-bar-container {
  width: 100%;
  height: 40px;
  background-color: green; }

#editor-body {
  width: 100%;
  height: calc(100% - 40px);
  background-color: blue;
  overflow: hidden; }

#flexible-container {
  height: calc(100% - 40px);
  width: 100%;
  background-color: #AAA;
  display: inline-block;
  position: absolute;
  top: 40px; }

.center-container {
  background-color: #ffaabb; }

.right-container {
  background-color: #aabbcc; }

.right-reflex-container {
  height: 100%;
  background-color: #aabbcc; }

.Toastify__toast-container--top-right {
  top: 40px;
  right: 1em; }

.info-toast-background {
  background-color: #3D3B3B; }

.error-toast-background {
  background-color: #E61E07; }

.warning-toast-background {
  background-color: #E9AA02; }

.success-toast-background {
  background-color: #01C094; }

/* Basic re-flex panel/splitter styling */
.reflex-container {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align items in Main Axis */
  -webkit-align-items: stretch;
          align-items: stretch;
  /* align items in Cross Axis */
  -webkit-align-content: stretch;
          align-content: stretch;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: -webkit-flex;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%; }

.reflex-container.horizontal {
  -webkit-flex-direction: column;
          flex-direction: column; }

.reflex-container.vertical {
  -webkit-flex-direction: row;
          flex-direction: row; }

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%; }

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%; }

.reflex-container > .reflex-splitter {
  background-color: #191919;
  z-index: 0; }

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: #AFAFAF;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.horizontal > .reflex-splitter {
  border-bottom: calc(4px / 2) solid #191919 !important;
  border-top: calc(4px / 2) solid #191919 !important;
  cursor: row-resize;
  width: 100%;
  height: 2px; }

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: calc(4px / 2) solid #AFAFAF !important;
  border-top: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container.vertical > .reflex-splitter {
  border-right: calc(4px / 2) solid #191919 !important;
  border-left: calc(4px / 2) solid #191919 !important;
  cursor: col-resize;
  height: 100%;
  width: 2px; }

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: calc(4px / 2) solid #AFAFAF !important;
  border-left: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 0; }

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  -webkit-transition: all 1.5s ease;
  transition: all 1.5s ease;
  opacity: 0.5; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  border-top: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  height: 17px !important;
  cursor: row-resize;
  margin: -calc(4px / 2) 0;
  width: 100%; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: calc(4px / 2) solid #AFAFAF !important;
  border-top: calc(4px / 2) solid #AFAFAF !important; }

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  border-left: calc(4px / 2) solid rgba(255, 255, 255, 0) !important;
  width: 17px !important;
  cursor: col-resize;
  margin: 0-calc(4px / 2);
  height: 100%; }

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: calc(4px / 2) solid #AFAFAF !important;
  border-left: calc(4px / 2) solid #AFAFAF !important; }

.timeline {
  background-color: #E6460E; }

#animation-timeline-container {
  height: 100%; }

#animation-timeline {
  width: 100%;
  height: 100%;
  border-left: 4px solid #191919;
  border-bottom: 4px solid #191919; }

.animation-timeline-add-keyframe-button {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 27px;
  left: 170px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.play-icon {
  cursor: pointer;
  height: 100%;
  width: 100%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.canvas-transforms-widget {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #191919;
  border-radius: 4px; }

#canvas-zoom-options-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

#zoom-tool-container {
  width: 40px;
  height: 40px; }

.canvas-transform-wick-button {
  border-radius: 0px; }

.zoom-tool {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.canvas-transform-item {
  padding: 2px; }

.canvas-transform-button {
  width: 40px;
  height: 40px;
  padding: 3px;
  padding-left: 1.5px;
  padding-right: 1.5px; }

.thin-transform-button {
  width: calc(40px * .6); }

.zoom-out-button {
  padding-left: 0px; }

.zoom-in-button {
  padding-right: 0px; }

.canvas-transform-icon {
  width: 90%; }

.play-button {
  width: 100%;
  height: 100%;
  padding: 3px; }

.play-button-container {
  width: calc(40px * 1.5);
  height: calc(40px * 1.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #191919;
  border-radius: 30px; }

.transforms-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.transforms-container:first-child {
  padding-left: 2px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.tool-box:hover {
  overflow-x: auto; }

.tool-button {
  float: left;
  min-width: 30px;
  width: 30px;
  height: 30px; }

.toolbox-break {
  height: 70%; }

.toolbox-item {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-actions-center {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85); }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.toolbox-actions-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.toolbox-break {
  width: 3px;
  min-width: 3px;
  background-color: #191919;
  margin-left: 8px;
  margin-right: 8px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.tool-box:hover {
  overflow-x: auto; }

.tool-button {
  float: left;
  min-width: 30px;
  width: 30px;
  height: 30px; }

.toolbox-break {
  height: 70%; }

.toolbox-item {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-actions-center {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85); }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.toolbox-actions-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }

.tool-button-container {
  width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.tool-button-select-container {
  width: 30px;
  height: 30px; }

.tool-button-icon {
  height: 80%; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.tool-box:hover {
  overflow-x: auto; }

.tool-button {
  float: left;
  min-width: 30px;
  width: 30px;
  height: 30px; }

.toolbox-break {
  height: 70%; }

.toolbox-item {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-actions-center {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85); }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.toolbox-actions-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }

#settings-panel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: transparent;
  -webkit-align-items: center;
          align-items: center; }

.settings-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.settings-text-label {
  color: #AFAFAF;
  font-size: 12px;
  bottom: 0px;
  text-align: center; }

.settings-input-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px; }

.settings-numeric-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: calc(2 * 55px); }

.settings-numeric-wick-input-container {
  width: calc(1 * 55px);
  margin-right: 4px; }

.settings-numeric-slider {
  width: calc(1 * 55px); }

.settings-checkbox-input {
  height: 30px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.setting-input-container {
  height: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 4px; }

.settings-numeric-top-container {
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.settings-numeric-slider-icon {
  height: calc(30px * .6);
  width: 50%; }

.settings-numeric-wick-input-container {
  height: 30px;
  width: 50%; }

.settings-numeric-bottom-container {
  position: absolute;
  z-index: 1;
  left: 50;
  top: 200;
  height: calc(2 * 30px);
  width: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: right;
          justify-content: right; }

.settings-slider-wick-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 4px;
  height: 100%;
  width: 105%;
  background-color: #303030;
  margin-top: 10px;
  margin-left: 5px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.tool-box:hover {
  overflow-x: auto; }

.tool-button {
  float: left;
  min-width: 30px;
  width: 30px;
  height: 30px; }

.toolbox-break {
  height: 70%; }

.toolbox-item {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-actions-center {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85); }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.toolbox-actions-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }

#settings-panel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: transparent;
  -webkit-align-items: center;
          align-items: center; }

.settings-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.settings-text-label {
  color: #AFAFAF;
  font-size: 12px;
  bottom: 0px;
  text-align: center; }

.settings-input-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px; }

.settings-numeric-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: calc(2 * 55px); }

.settings-numeric-wick-input-container {
  width: calc(1 * 55px);
  margin-right: 4px; }

.settings-numeric-slider {
  width: calc(1 * 55px); }

.settings-checkbox-input {
  height: 30px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.setting-input-container {
  height: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 4px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.tool-box {
  width: 100%;
  height: 100%;
  background-color: #303030;
  padding-left: 2px;
  padding-right: 4px;
  border: 4px solid #191919;
  border-top: none;
  border-right: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }

.tool-box:hover {
  overflow-x: auto; }

.tool-button {
  float: left;
  min-width: 30px;
  width: 30px;
  height: 30px; }

.toolbox-break {
  height: 70%; }

.toolbox-item {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px; }

.toolbox-actions-center {
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.color-container {
  cursor: pointer;
  width: calc(30px * .85);
  height: calc(30px * .85);
  float: left;
  min-width: calc(30px * .85); }

#tool-box-stroke-color-button {
  border-radius: 20px; }

#tool-box-fill-color-button {
  border-radius: 20px; }

#stroke-color-picker-container {
  box-sizing: border-box; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  float: right; }

.toolbox-actions-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.toolbox-actions-right-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }

#settings-panel-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: transparent;
  -webkit-align-items: center;
          align-items: center; }

.settings-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.more-canvas-actions-popover {
  background-color: #303030;
  border: 2px solid #191919;
  max-width: 375px; }

.canvas-actions-widget {
  height: 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 1px; }

.actions-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.canvas-action-button {
  width: 35px;
  height: 35px;
  padding: 2px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.asset-item {
  -webkit-transition: .2s;
  transition: .2s;
  width: calc(100% - 2 * 4px);
  height: 24px;
  line-height: 24px;
  background-color: #3B3B3B;
  color: #797979;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  vertical-align: center;
  border-radius: 2px;
  overflow: hidden;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.asset-item:hover {
  background-color: #303030;
  color: #AFAFAF;
  cursor: -webkit-grab;
  cursor: grab; }

.asset-icon {
  width: calc(24px * .8);
  height: calc(24px * .8); }

.asset-name-text {
  margin-left: 4px;
  white-space: nowrap;
  overflow: scroll;
  height: 100%;
  overflow: hidden; }

.asset-item.asset-selected {
  -webkit-transition: .2s;
  transition: .2s;
  background-color: #303030;
  color: #AFAFAF; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.asset-library {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #303030;
  border-right: 4px solid #191919;
  border-bottom: 4px solid #191919;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.asset-library-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 6px; }

.asset-library-title-text {
  color: #AFAFAF;
  font-size: 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  width: 200px; }

.btn-asset-upload {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-left: auto; }

.btn-asset-upload-text {
  font-size: 18px; }

.asset-library-body {
  height: 100%;
  background-color: #4A4A4A;
  margin-top: 6px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.asset-library-filter {
  top: 0;
  width: 100%;
  height: 40px;
  max-height: 40px;
  background-color: #3B3B3B;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.asset-library-filter-icon {
  height: calc(40px * .5);
  width: calc(40px * .5);
  margin-left: 4px;
  margin-right: 4px; }

.asset-library-asset-container {
  height: 100%;
  overflow: hidden; }

.asset-library-asset-container:hover {
  overflow-y: auto; }

#asset-library-filter-input {
  background: transparent;
  border: none;
  color: #AFAFAF; }

#asset-library-filter-input::-webkit-input-placeholder {
  color: #AFAFAF; }

#asset-library-filter-input::-ms-input-placeholder {
  color: #AFAFAF; }

#asset-library-filter-input::placeholder {
  color: #AFAFAF; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

.error-marker {
  position: absolute;
  background-color: #FF5372; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-tab-code-editor {
  height: 100%; }

#add-scripts-panel-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #3F3D3D; }

#code-editor-add-script-tab {
  width: 15px;
  height: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#select-sub-tab-list {
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 15px;
  overflow: auto;
  margin-bottom: 10px; }

.delete-tab-container {
  height: 17px;
  width: 17px;
  margin-left: 4px; }

.add-script-container {
  overflow: auto;
  height: calc(100% - 2 * 40px - 10px);
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding-left: 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start; }

.add-script-button-container {
  width: 150px;
  min-width: 150px;
  height: 95px;
  background-color: #292927;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 6px; }

.add-script-button-container.already-used-script {
  background-color: #3F3D3D;
  color: #919191; }

.add-script-button-container:hover {
  background-color: #353434;
  color: #B1B1B1; }

.add-script-button-container.already-used-script:hover {
  background-color: #3F3D3D;
  color: #919191;
  cursor: default; }

.add-script-icon {
  width: 15px;
  height: 15px;
  margin-right: 4px; }

.add-script-button-title {
  display: -webkit-flex;
  display: flex;
  height: 20px;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 4px;
  font-size: 16px;
  margin-top: 2px; }

.add-script-description {
  padding: 10px;
  padding-left: 4px;
  font-size: 12px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-tab-code-editor {
  height: 100%; }

#add-scripts-panel-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #3F3D3D; }

#code-editor-add-script-tab {
  width: 15px;
  height: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#select-sub-tab-list {
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 15px;
  overflow: auto;
  margin-bottom: 10px; }

.delete-tab-container {
  height: 17px;
  width: 17px;
  margin-left: 4px; }

.add-script-container {
  overflow: auto;
  height: calc(100% - 2 * 40px - 10px);
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding-left: 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-tab-code-editor {
  height: 100%; }

#add-scripts-panel-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #3F3D3D; }

#code-editor-add-script-tab {
  width: 15px;
  height: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

#select-sub-tab-list {
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 15px;
  overflow: auto;
  margin-bottom: 10px; }

.delete-tab-container {
  height: 17px;
  width: 17px;
  margin-left: 4px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.react-tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  background-color: #353434; }
  .react-tabs__tab-list {
    width: 100%;
    margin: 0 0 0px;
    padding: 0;
    white-space: nowrap;
    min-height: 40px;
    overflow: hidden; }
  .react-tabs__tab-list:hover {
    width: 100%;
    margin: 0 0 0px;
    padding: 0;
    white-space: nowrap;
    min-height: 40px;
    overflow-x: auto; }
  .react-tabs__tab {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    padding: 3px 6px;
    cursor: pointer;
    background-color: #353434;
    color: #B1B1B1;
    border-radius: 5px 5px 0 0;
    height: 100%;
    font-weight: 800;
    font-family: 'Nunito Sans'; }
    .react-tabs__tab--selected {
      background: #3F3D3D;
      color: #919191; }
    .react-tabs__tab--disabled {
      color: GrayText;
      cursor: default; }
    .react-tabs__tab:focus {
      outline: none; }
  .react-tabs__tab-panel {
    display: none;
    width: 100%;
    height: 100%; }
    .react-tabs__tab-panel--selected {
      display: block;
      background-color: #919191;
      height: 100%; }

.react-tab-yellow {
  background-color: #E9AA02;
  color: black; }

.react-tab-yellow.react-tabs__tab--selected {
  background-color: #FFE243; }

.react-tab-red {
  background-color: #E61E07;
  color: white; }

.react-tab-red.react-tabs__tab--selected {
  background-color: #FF5372; }

.react-tab-sky {
  background-color: #00709c;
  color: white; }

.react-tab-sky.react-tabs__tab--selected {
  background-color: #00ADEF;
  color: white; }

.react-tab-green {
  background-color: #01C094;
  color: white; }

.react-tab-green.react-tabs__tab--selected {
  background-color: #29F1A3; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-details-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 150px;
  height: 100%;
  float: left;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

.code-editor-selection-preview {
  width: 100%;
  height: 100%; }

.code-editor-thumbnail-container {
  width: 150px;
  height: 50px;
  background-color: #353434;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.code-editor-thumbnail-highlight {
  position: relative;
  top: calc(80px / 2 * -1);
  width: 80px;
  height: 80px;
  background-color: #292927;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 40px; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-details-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 150px;
  height: 100%;
  float: left;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

#code-editor-reference {
  width: 150px;
  height: calc(100% - 50px);
  background-color: #353434;
  float: none;
  color: #B1B1B1;
  padding-left: 10px;
  overflow: auto; }

.reference-tab-title {
  font-size: 16px;
  cursor: pointer;
  width: 100%; }

.reference-tab-title:hover {
  -webkit-transition: .6s;
  transition: .6s;
  color: white; }

.reference-tab-container {
  margin-left: 10px; }

.reference-tab-elements {
  max-height: auto;
  -webkit-transition: max-height 1s ease-out;
  transition: max-height 1s ease-out;
  margin-left: 10px;
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.reference-tab-elements.tab-closed {
  max-height: 0;
  overflow: hidden; }

.code-editor-reference-title-text {
  font-size: 22px; }

.tab-element {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */
a:link {
  color: #1EE29A; }

a:hover {
  color: #12865c; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal-body {
  border-radius: 4px;
  background-color: #3D3B3B;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Nunito Sans';
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); }

/*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
.btn-wick-warning {
  background: #E6460E;
  border: #E6460E;
  color: #000; }

.btn-wick-warning:hover {
  background: #c23b0c;
  border: #c23b0c; }

.btn-wick-warning:active {
  background: #aa340a;
  border: #aa340a; }

.btn-wick-accept {
  background: #01C094;
  border: #01C094;
  color: #000; }

.btn-wick-accept:hover {
  background: #019a77;
  border: #019a77; }

.btn-wick-accept:active {
  background: #018163;
  border: #018163; }

#code-editor-resizeable {
  z-index: 1000;
  background-color: #464646;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px; }

.ace-editor {
  box-sizing: border-box; }

.code-editor-drag-handle {
  background-color: #292927;
  width: 100%;
  height: 40px;
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden; }

.code-editor-title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FFFFFF;
  float: left;
  height: 100%;
  left: 150px;
  position: relative;
  left: 150px; }

.code-editor-information {
  margin-left: 4px;
  color: #ADADAD; }

.code-editor-information.code-editor-error-information {
  color: #FF5372; }

.code-editor-body {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden; }

.code-editor-code-panel {
  width: calc(100% - 150px);
  height: 100%;
  float: left; }

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: #292927;
  border-radius: 3px;
  cursor: pointer; }

.code-editor-close-button:hover {
  background-color: #29F1A3; }

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: #919191;
  color: #626262;
  padding-left: 6px; }

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.code-editor-details-panel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 150px;
  height: 100%;
  float: left;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

