@import 'Editor/_wickbrand.scss';
@import '../_popoutcodeditor.scss';

.code-editor-details-panel {
  display: flex;
  flex-direction: column;
  width: $code-editor-details-width;
  height: 100%;
  float: left;
  flex-grow: 0;
  flex-shrink: 0;
}


