/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../toolsettings.scss'; 

$settings-input-size: 55px;

.settings-text-label {
  color: $editor-primary-property-text;
  font-size: 12px;
  bottom: 0px;
  text-align: center;
}

.settings-input-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.settings-numeric-input-container {
  display: flex;
  align-items: center;
  width: calc(2 * #{$settings-input-size});
}

.settings-numeric-wick-input-container {
  width: calc(1 * #{$settings-input-size});
  margin-right: 4px;
}

.settings-numeric-slider {
  width: calc(1 * #{$settings-input-size});
}

.settings-checkbox-input {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
}

.setting-input-container {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
}
