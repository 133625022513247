/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$welcome-modal-padding: 28px; 

.welcome-modal-body {
  width: 670px;
  min-width: 670px;  
  height: 420px;
  padding: 0px; 
}

#welcome-modal-interior-content {
  display: flex;
  flex-direction: row;
  width: 100%; 
  height: 100%;
}

#welcome-image-container {
  width: 320px; 
  min-width: 320px; 
}

#welcome-image {
  width: 100%; 
  height: 100%; 
}

#welcome-message-container {
  background-color: $editor-modal-dark;
  width: 350px;
  min-width: 350px; 
  padding: $welcome-modal-padding; 
  padding-top: 30px; 
  position: relative; 
}

#welcome-modal-title {
  color: $editor-modal-text-white; 
  font-size: 22px;
  font-weight: bold; 
  white-space: nowrap;
}

#welcome-modal-version {
  color: $editor-modal-text-gray; 
  font-size: 16px;
  font-weight: 600; // Semi bold; 
}

#welcome-modal-subtitle {
  color: $wick-red;
  font-size: 20px;
  font-weight: bold; 
  margin-top: 20px;
}

#welcome-modal-message {
  margin-top: 30px; 
  color: $editor-modal-text-gray; 
  font-size: 22px; 
}

#welcome-modal-forum-link {
  color: $editor-modal-text-gray; 
  font-size: 14px; 
  position: absolute;
  left: $welcome-modal-padding;
  bottom: calc(#{$welcome-modal-padding} + 40px); 
}

.welcome-modal-highlight {
  color: $editor-modal-text-highlight; 
}

.welcome-modal-main-container {
  height: 100%;
}

#welcome-modal-footer {
  width: calc(100% - calc(2 * #{$welcome-modal-padding})); 
  height: 28px;
  display: flex;
  flex-direction: row; 
  position: absolute;
  bottom: $welcome-modal-padding;
}

#welcome-modal-cancel {
  width: 80px; 
  height: 100%; 
  margin-left: auto; 
}

#welcome-modal-accept {
  width: 65px;
  height: 100%; 
  margin-left: 8px;
} 

#welcome-modal-tutorial-links {
  display: flex;
}

.welcome-link {
  margin-left: 12px;
}

.welcome-link:first-child {
  margin-left: 0px;
}