/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../_inspector.scss';

.inspector-title {
  height: $inspector-title-height;
  padding-left: $inspector-padding;
  padding-right: $inspector-padding;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.inspector-title-container {
  box-shadow: 0px 2px 4px black;
  position: relative;
}

.inspector-title-name {
  font-size: 22px;
  color: $editor-secondary-text;
  font-family: 'Nunito Sans';
  display: inline-block;
}

.inspector-title-selection-type {
  height: 80%;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: $editor-primary-property-text;
  margin-left: 8px;
}
