/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../_inspector.scss';

$inspector-element-margin: 4px;
$row-identifier-width: 175px;
$input-container-margin: 4px;
$input-margin: calc(2*#{$inspector-element-margin});
$input-space: calc(calc(100% - #{$row-identifier-width}) - #{$input-margin});
$single-input-size: 100%;
$double-input-container-width: $input-space;
$double-input-divider-width: 20%;
$double-input-unit-width: 40%;

.inspector-row-identifier {
  width: $row-identifier-width;
  max-width: $row-identifier-width;
  height: 100%;
  color: $editor-primary-property-text;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
}

.inspector-row-identifier:hover {
  overflow: auto;
}

.inspector-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: $inspector-row-height;
  margin-bottom: 4px;
}

.inspector-row:last-child {
  margin-bottom: 0px;
}

.inspector-preview-row {
  height: $inspector-image-preview-size;
}

.inspector-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90%;
  margin-left: $input-container-margin;
}

.inspector-input-element {
  display: inline-block;
  height: 100%;
}

.single-input-element {
  width: $single-input-size;
}

.double-input-element {
  width: $double-input-unit-width;
}

.input-divider {
  color: $interface-primary-text;
  width: $double-input-divider-width;
  text-align: center;
}

.double-input {
  height: 100%;
  display: flex;
}
