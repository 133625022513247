/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$code-editor-tab-list-height: 40px;

$code-editor-drag-handle-height: 40px;
$code-editor-details-width: 150px;
$code-editor-thumbnail-height: 100px;

$code-editor-thumbnail-container-height: 90px - $code-editor-drag-handle-height;
$code-editor-thumbnail-highlight-size: 80px;
$code-editor-thumbnail-size: 50px;

#code-editor-resizeable {
  z-index: 1000;
  background-color: $interface-primary;
  border: none;
  box-shadow: 2px 2px 5px;
  border-radius: 3px;
}

.ace-editor {
  box-sizing: border-box;
}

.code-editor-drag-handle {
  background-color: $editor-popout-primary;
  width: 100%;
  height: $code-editor-drag-handle-height;
  cursor: grab;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
}

.code-editor-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $interface-primary-text;
  float: left;
  height: 100%;
  left: $code-editor-details-width;
  position: relative;
  left: $code-editor-details-width;
}

.code-editor-information {
  margin-left: 4px;
  color: $interface-secondary-text;
}

.code-editor-information.code-editor-error-information {
  color: $wick-red-light;
}

.code-editor-body {
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100% - #{$code-editor-drag-handle-height});
  overflow: hidden;
}

.code-editor-code-panel {
  width: calc(100% - #{$code-editor-details-width});
  height: 100%;
  float: left;
}

.code-editor-close-button {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: $editor-popout-primary;
  border-radius: 3px;
  cursor: pointer;
}

.code-editor-close-button:hover {
  background-color: #{$wick-green-light};
}

.code-editor-unscriptable-warning {
  width: 100%;
  height: 100%;
  background-color: $interface-tertiary;
  color: $interface-tertiary-text;
  padding-left: 6px;
}

.code-editor-thumbnail {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 15px;
  border-radius: 30px;
  width: $code-editor-thumbnail-size;
  height: $code-editor-thumbnail-size;

  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
