@import 'Editor/_wickbrand.scss';

$canvas-action-size: 35px;

.more-canvas-actions-popover {
  background-color: $editor-primary;
  border: 2px solid $editor-primary-outline;
  max-width: 375px;
}

.canvas-actions-widget {
  height: $canvas-action-size;
  display: flex;
  align-items: center;
  margin: 1px;
}

.actions-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.canvas-action-button {
  width: $canvas-action-size;
  height: $canvas-action-size;
  padding: 2px;
}
