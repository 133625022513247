/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';

$tool-button-margin: 3px;
$toolbox-min-button-size: 30px;
$toolbox-height: 40px;

.tool-box {
  width: 100%;
  height: 100%;
  background-color: $editor-primary;
  padding-left: 2px;
  padding-right: 4px;
  border: $editor-outline-padding solid $editor-primary-outline;
  border-top: none;
  border-right: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden; 
}

.tool-box:hover {
  overflow-x: auto;
}

.tool-button {
  float: left;
  min-width: $toolbox-min-button-size;
  width: $toolbox-min-button-size;
  height: $toolbox-min-button-size;
}

.toolbox-break {
  height: 70%;
}

.toolbox-item {
  width: $toolbox-min-button-size;
  min-width: $toolbox-min-button-size;
  height: $toolbox-min-button-size;
  margin-left: $tool-button-margin;
  margin-right: $tool-button-margin;
}

.toolbox-actions-center {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-container {
  cursor: pointer;
  width: calc(#{$toolbox-min-button-size} * .85);
  height: calc(#{$toolbox-min-button-size} * .85);
  float: left;
  min-width:  calc(#{$toolbox-min-button-size} * .85);
}

#tool-box-stroke-color-button {
  border-radius: 20px;
}

#tool-box-fill-color-button {
  border-radius: 20px;
}

#stroke-color-picker-container {
  box-sizing: border-box;
}

.toolbox-actions-right{
  display: flex; 
  flex-direction: row;
  align-items: center;
  float: right; 
}

.toolbox-actions-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbox-actions-right-container {
  display: flex;
  flex-direction: row;
  margin-left: auto; 
  align-items: center;
  height: 100%;
}