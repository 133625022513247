/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../toolsettingsinput.scss';

$tool-settings-size: $toolbox-min-button-size;
$tool-settings-bottom-container-height: calc(2 * #{$tool-settings-size});

.settings-numeric-slider-container {
    
}

.settings-numeric-top-container {
    height: $tool-settings-size;
    display: flex;
    align-items: center;
}

.settings-numeric-slider-icon {
    height: calc(#{$tool-settings-size} * .6);
    width: 50%; 
}

.settings-numeric-wick-input-container {
    height: $tool-settings-size;
    width: 50%;
}

.settings-numeric-bottom-container {
    position: absolute;
    z-index: 1;
    left: 50; 
    top: 200;
    height: $tool-settings-bottom-container-height;
    width: 50px;
    display: flex;
    justify-content: right;
}

.settings-slider-wick-input-container {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    height: 100%; 
    width: 105%;
    background-color: $editor-primary; 
    margin-top: 10px;
    margin-left: 5px;
}