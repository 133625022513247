/*
 * Copyright 2018 WICKLETS LLC
 *
 * This file is part of Wick Editor.
 *
 * Wick Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * Wick Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Wick Editor.  If not, see <https://www.gnu.org/licenses/>.
 */

@import 'Editor/_wickbrand.scss';
@import '../_popoutcodeditor.scss';

.react-tabs {
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  background-color: $editor-popout-secondary;

  &__tab-list {
    width:100%;
    margin: 0 0 0px;
    padding: 0;
    white-space: nowrap;
    min-height: $code-editor-tab-list-height;
    overflow: hidden;
  }

  &__tab-list:hover {
    width:100%;
    margin: 0 0 0px;
    padding: 0;
    white-space: nowrap;
    min-height: $code-editor-tab-list-height;
    overflow-x: auto;
  }

  &__tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-bottom: none;
    bottom: -1px;
    position: relative;
    padding: 3px 6px;
    cursor: pointer;
    background-color: $editor-popout-secondary; 
    color: $editor-popout-secondary-text;
    border-radius: 5px 5px 0 0;
    height: 100%;
    font-weight: 800;
    font-family: 'Nunito Sans';

    &--selected {
      background: $editor-popout-tertiary;
      color: $editor-popout-tertiary-text;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;
    width: 100%;
    height: 100%;

    &--selected {
      display: block;
      background-color: $interface-tertiary;
      height: 100%;
    }
  }
}

.react-tab-yellow {
  background-color: $wick-yellow-dark;
  color: $wick-yellow-text;
}

.react-tab-yellow.react-tabs__tab--selected {
  background-color: $wick-yellow-light;
}

.react-tab-red {
  background-color: $wick-red-dark;
  color: $wick-red-text;
}

.react-tab-red.react-tabs__tab--selected {
  background-color: $wick-red-light;
}

.react-tab-sky {
  background-color: $wick-active-blue-dark;
  color: $wick-active-blue-text;
}

.react-tab-sky.react-tabs__tab--selected {
  background-color: $wick-active-blue;
  color: $wick-active-blue-text;
}

.react-tab-green {
  background-color: $wick-green-dark;
  color: $wick-green-text;
}

.react-tab-green.react-tabs__tab--selected {
  background-color: $wick-green-light;
}